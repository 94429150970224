<template>
  <div>
    <header-bar-home
      :swiperActiveIndex="swiperActiveIndex"
      :white="isWhite"
      :show-menu="footerSwiperProgress<0.3"
      @swiper-to="swiperTo"
    />
    <div class="swiper swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide home-swiper-slide slide-1">
          <div
            class="home1"
            :style="{transform:`scale(${scale})`}"
          >
            <!-- <p class="home1-line"><img src="@/assets/image/home1-line.webp"></p> -->
            <p
              class="welcome-title"
              :style="{transform:`scale(${scale*1})`}"
            >
              <span class="eng">Welcome to</span>
              <span class="zw">掌玩</span>
            </p>
            <div class="welcome-content">
              <p>
                「掌玩网络」总部位于杭州，成立于2014年，主要业务包括在线阅读、社交电商、知识付费等，是国内知名的新媒体内容矩阵平台，也是国家级高新技术企业。
              </p>
              <p> 「掌玩网络」自主研发的“掌玩BI”智能操作系统，赋能业务实现用户精细化运营，高效打通流量获取、运营、变现三大环节.....</p>
            </div>
            <div class="welcome-more">
              <router-link
                to="/qiye"
                class="more"
              >MORE</router-link>
            </div>
          </div>
        </div>
        <div class="swiper-slide home-swiper-slide slide-2">
          <div class="home-box">
            <div class="home-box-header">
              <p class="title">
                <span class="cn">公司视频</span>
                <span class="en">Video</span>
              </p>
              <!-- <router-link
                to="/yewu"
                class="more"
              >MORE</router-link> -->
            </div>
            <div class="video">
              <div class="swiper-slide">
                <div class="img">
                  <video
                    id="video"
                    src="https://guanwang-1255977238.cos.ap-shanghai.myqcloud.com/guanwang.mp4"
                    poster="../assets/business.jpg"
                    controls
                  >
                  </video>
                </div>
                <div class="right">
                  <p class="title">腾讯广告 王者说</p>
                  <p class="content">猫老大采访视频</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide home-swiper-slide slide-2">
          <div class="home-box">
            <div class="home-box-header">
              <p class="title">
                <span class="cn">业务介绍</span>
                <span class="en">Business</span>
              </p>
              <router-link
                to="/yewu"
                class="more"
              >MORE</router-link>
            </div>
            <div class="yewu">
              <div class="rongyu-swiper-container home-swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="img yewu-img4"></div>
                    <div class="right">
                      <p class="title">短剧项目</p>
                      <p class="content">短剧业务于2022年开始布局，与字节、快手、腾讯、百度等平台深度合作，凭借掌玩强大的广告投放能力，日消耗不断突破，成功在市场上推广多部爆款短剧；随着业务多元化发展，掌玩同步布局原创内容平台，成功打造多部原创短剧剧集，形成自有短剧生态链，致力于搭建国内顶尖的短剧平台。</p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="img yewu-img1"></div>
                    <div class="right">
                      <p class="title">数字阅读</p>
                      <p class="content">业务现在增涨迅猛，是包括阅文、点众、常读、阳光、掌中云、花笙、网易在内的各大小说平台核心代理、分发商。每年在微信、抖音、快手以及Facebook等平台广告消耗近10亿，是行业数一数二的头部企业，成为行业风向标，引领行业发展。</p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="img yewu-img2"></div>
                    <div class="right">
                      <p class="title">游戏项目</p>
                      <p class="content">是公司旗下核心业务，团队拥有丰富的游戏运营推广经验和行业资源，运营至今已成功发行过几十款大型游戏。团队目前已突破200人。</p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="img yewu-img3"></div>
                    <div class="right">
                      <p class="title">新媒体广告</p>
                      <p class="content">「新媒体广告」是掌玩的起家业务，曾打造《一星期读一本书》《豪哥说车》《糗事大百科》等知名公众号；截止目前公众号粉丝累计过亿，在行业内拥有极大的影响力。</p>
                    </div>
                  </div>
                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide home-swiper-slide slide-3">
          <div class="home-box">
            <div class="home-box-header">
              <p class="title">
                <span class="cn">企业荣誉</span>
                <span class="en">Enterprise honor</span>
              </p>
              <router-link
                to="/rongyu"
                class="more"
              >MORE</router-link>
            </div>
            <div class="rongyu">
              <div class="rongyu-swiper-container home-swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="img rongyu-img8"></div>
                    <div class="right">
                      <p class="title">最佳合作伙伴</p>
                      <!-- <p class="title">2019</p> -->
                      <p class="content">
                        2022年被点众科技授予，“最佳合作伙伴”称号
                      </p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="img rongyu-img7"></div>
                    <div class="right">
                      <p class="title">充值王者</p>
                      <!-- <p class="title">2019</p> -->
                      <p class="content">
                        2021年荣登腾讯广告自媒体王者榜，荣获“最强充值王”称号
                      </p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="img rongyu-img5"></div>
                    <div class="right">
                      <p class="title">消耗王者</p>
                      <!-- <p class="title">2019</p> -->
                      <p class="content">
                        2020年H1被腾讯广告授予“腾讯广告自媒体小说消耗王者”称号，并颁发特制奖杯
                      </p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="img rongyu-img6"></div>
                    <div class="right">
                      <p class="title">测书王者</p>
                      <!-- <p class="title">2019</p> -->
                      <p class="content">
                        2020年H1被腾讯广告授予“腾讯广告自媒体小说测书王者”称号，并颁发特制奖杯
                      </p>
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="img rongyu-img10"></div>
                    <div class="right">
                      <p class="title">最佳战略合作伙伴</p>
                      <!-- <p class="title">2019</p> -->
                      <p class="content">
                        2020年9月9日，广州简知授予掌玩网络最佳战略合作伙伴称号
                      </p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="img rongyu-img9"></div>
                    <div class="right">
                      <p class="title">优质合作伙伴</p>
                      <!-- <p class="title">2019</p> -->
                      <p class="content">
                        2019年度获得江苏文旭授予掌玩网络优质合作伙伴称号
                      </p>
                    </div>
                  </div>
                  <!-- <div class="swiper-slide">
                    <div class="img rongyu-img1"></div>
                    <div class="right">
                      <p class="title">小说行业速增王者</p>
                      <p class="title">2019</p>
                      <p class="content">
                        2019年9月被腾讯广告认证为“小说行业速增王者”，并颁发相关奖杯
                      </p>
                    </div>
                  </div> -->
                  <div class="swiper-slide">
                    <div class="img rongyu-img2"></div>
                    <div class="right">
                      <p class="title">计算机软件著作权</p>
                      <!-- <p class="title">2019</p> -->
                      <p class="content">
                        2016年至2019年，经国家版权局批准并颁发各类计算机软件著作权登记证书
                      </p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="img rongyu-img3"></div>
                    <div class="right">
                      <p class="title">出版物经营许可证</p>
                      <!-- <p class="title">2019</p> -->
                      <p class="content">
                        2019年9月经杭州钱塘新区管理委员会批准并颁发《中华人民共和国出版物经营许可证》
                      </p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="img rongyu-img4"></div>
                    <div class="right">
                      <p class="title">国家级高新技术企业</p>
                      <!-- <p class="title">2019</p> -->
                      <p class="content">
                        2018年11月被浙江省科学技术厅、浙江省财政厅、浙江省税务局共同评定为“国家级高新技术企业”，并颁发相关证书
                      </p>
                    </div>
                  </div>

                  <!-- <div class="swiper-slide">
                    <div class="img rongyu-img2"></div>
                    <div class="right">
                      <p class="title">新媒体</p>
                      <p class="content">依托自有互联网营销平台，为广告客户提供全方位营销服务，并为流量主客户制定流量变现解决方案</p>
                    </div>
                  </div> -->
                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide home-swiper-slide slide-4">
          <div class="home-box">
            <div class="home-box-header">
              <p class="title">
                <span class="cn">企业招聘</span>
                <span class="en">Join Us</span>
              </p>
              <router-link
                to="/join"
                class="more"
              >MORE</router-link>
            </div>
            <div class="zhaopin">
              <div class="zhaopin-swiper-container home-swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <router-link to="/join">
                      <div class="img zhaopin-pic zhaopin-img1"><span>「 掌玩-管培生 」</span></div>
                    </router-link>
                  </div>
                  <div class="swiper-slide">
                    <router-link to="/join">
                      <div class="img zhaopin-pic zhaopin-img2"><span>「 掌玩-短视频创意师 」</span></div>

                    </router-link>
                  </div>
                  <div class="swiper-slide">
                    <router-link to="/join">
                      <div class="img zhaopin-pic zhaopin-img3"><span>「 掌玩-广告优化师 」</span></div>

                    </router-link>
                  </div>
                  <div class="swiper-slide">
                    <router-link to="/join">
                      <div class="img zhaopin-pic zhaopin-img4"><span>「 掌玩-游戏用户运营 」</span></div>

                    </router-link>
                  </div>
                  <div class="swiper-slide">
                    <router-link to="/join">
                      <div class="img zhaopin-pic zhaopin-img5"><span>「 掌玩-在线GM 」</span></div>

                    </router-link>
                  </div>
                  <div class="swiper-slide">
                    <router-link to="/join">
                      <div class="img zhaopin-pic zhaopin-img6"><span>「 掌玩-短剧编辑 」</span></div>
                    </router-link>
                  </div>
                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide home-swiper-slide slide-5">
          <div class="home-box">
            <div class="home-box-header">
              <p class="title">
                <span class="cn">人才培养</span>
                <span class="en">Talent Development</span>
              </p>

            </div>
            <div class="rencai">
              <div class="rencai-swiper-container home-swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="img rencai-img3"></div>
                    <div class="right">
                      <p class="title">企业培训</p>
                      <p class="content">
                        公司重视人才的可持续发展，定期开展恒星计划、行星计划、进阶新人训、公开课等企业培训项目，不断提高在职员工的职业素质，致力于培养一专多能的“T”型人才。
                      </p>
                      <p>
                        <router-link
                          to="/rencai"
                          class="more"
                        >MORE</router-link>
                      </p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="img rencai-img1"></div>
                    <div class="right">
                      <p class="title">管培生计划</p>
                      <p class="content">
                        管培生计划是让管培生经过内部重要业务部门的轮岗学习，为公司培养基层管理岗位人才的一项重要且长期的计划
                      </p>
                      <p>
                        <router-link
                          to="/rencai"
                          class="more"
                        >MORE</router-link>
                      </p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="img rencai-img2"></div>
                    <div class="right">
                      <p class="title">掌成计划</p>
                      <p class="content">掌成计划是公司专门为内部重点员工开拓的除管培生外另一条储干培养通道，由内部选拔的优秀内训师和聘请的外训讲师进行授课，打造掌玩型管理人才。</p>
                      <p>
                        <router-link
                          to="/rencai"
                          class="more"
                        >MORE</router-link>
                      </p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="img rencai-img5"></div>
                    <div class="right">
                      <p class="title">外出培训</p>
                      <p class="content">公司将不定期组织中高层管理者外出学习，从而提高中高层干部的眼界、管理能力和理论知识，并能在实际工作中发挥明显作用</p>
                      <p>
                        <router-link
                          to="/rencai"
                          class="more"
                        >MORE</router-link>
                      </p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="img rencai-img4"></div>
                    <div class="right">
                      <p class="title">经营者计划</p>
                      <p class="content">公司将不定期选拔认同掌玩网络价值观，有潜力、并在核心岗位有突出贡献的年轻管理者，给予早期经营者的回报。</p>
                      <p>
                        <router-link
                          to="/rencai"
                          class="more"
                        >MORE</router-link>
                      </p>
                    </div>
                  </div>
                  <!-- <div class="swiper-slide">
                    <div class="img rencai-img6"></div>
                    <div class="right">
                      <p class="title">潜龙计划</p>
                      <p class="content">公司将不定期选拔认同掌玩价值观，有潜力、并在核心岗位有突出贡献的年轻管理者，给予早期经营者的回报，激励他们以经营者心态和投入度，和公司共绘未来美好蓝图</p>
                      <p>
                        <router-link
                          to="/rencai"
                          class="more"
                        >MORE</router-link>
                      </p>
                    </div>
                  </div> -->

                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide home-swiper-slide slide-6">
          <div class="home-box">
            <div class="home-box-header">
              <p class="title">
                <span class="cn">发展历程</span>
                <span class="en">Milestone</span>
              </p>
              <router-link
                to="/fazhan"
                class="more"
              >MORE</router-link>
            </div>
            <div class="zhaopin fazhan">
              <div class="fazhan-swiper-container home-swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/fazhanimg/fz1.png"
                      content="梦开始的地方，掌玩成立于和达城小区3楼"
                      title="2014年6月"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/fazhanimg/fz2.jpg"
                      content="掌玩第一次年会"
                      title="2015年春节"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/fazhanimg/fz4.jpg"
                      content="掌玩2016年年会晚宴"
                      title="2016年春节"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/fazhanimg/fz5.jpg"
                      content="掌玩乔迁至东部国际商务中心举办乔迁晚宴"
                      title="2017年4月"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/fazhanimg/fz6.jpg"
                      content="郑总和罗涛仅用时30分钟吃了一顿小龙虾就敲定了合作"
                      title="2017年5月4日"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2017/9.jpg"
                      content="周年庆"
                      title="2017年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/fazhanimg/fz7.jpg"
                      content="掌玩2018年掌玩年会"
                      title="2018年春节"
                      @preview="handlePreview"
                    />
                  </div>
                  <!-- <div class="swiper-slide">
                    <home-fazhan
                      img="/fazhanimg/fz8.jpg"
                      content="王成和蒋秀杰加入掌玩，成为合伙人"
                      title="2018年"
                      @preview="handlePreview"
                    />
                  </div> -->
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2018/3.jpg"
                      content="周年庆"
                      title="2018年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/fazhanimg/fz9.jpg"
                      content="掌玩2019年年会"
                      title="2019年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/fazhanimg/fz10.jpg"
                      content="掌玩2020年年会"
                      title="2020年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2020/2.jpg"
                      content="六周年庆"
                      title="2020年"
                      @preview="handlePreview"
                    />
                  </div>
                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide home-swiper-slide slide-6">
          <div class="home-box">
            <div class="home-box-header">
              <p class="title">
                <span class="cn">文化展示</span>
                <span class="en">Culture</span>
              </p>
              <router-link
                to="/wenhua"
                class="more"
              >MORE</router-link>
            </div>
            <div class="zhaopin fazhan">
              <div class="fazhan-swiper-container home-swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/wenhua/new/kmycs.jpeg"
                      content="开门迎新春"
                      title=""
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/wenhua/new/nsjhd.jpeg"
                      content="女生节活动"
                      title=""
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/wenhua/new/zwyyh.jpeg"
                      content="亚运在掌玩"
                      title=""
                      @preview="handlePreview"
                    />
                  </div>
                  <!-- <div class="swiper-slide">
                    <home-fazhan
                      img="/wenhua/wenhua1.png"
                      content="入职周年"
                      title=""
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/wenhua/wenhua2.png"
                      content="节假日福利"
                      title=""
                      @preview="handlePreview"
                    />
                  </div> -->
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/wenhua/wenhua5.png"
                      content="下午茶"
                      title=""
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/wenhua/wenhua6.png"
                      content="年会"
                      title=""
                      @preview="handlePreview"
                    />
                  </div>
                  <!-- <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2016/1.jpg"
                      content="宁波旅游"
                      title="2016年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2017/1.jpg"
                      content="运营部春游活动"
                      title="2017年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2017/2.jpg"
                      content="大连旅游"
                      title="2017年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2017/3.jpg"
                      content="潜龙计划"
                      title="2017年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2017/4.jpg"
                      content="塞班旅游"
                      title="2017年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2017/5.jpg"
                      content="三亚旅游"
                      title="2017年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2017/6.jpg"
                      content="台球比赛"
                      title="2017年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2017/7.jpg"
                      content="桐庐团建"
                      title="2017年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2017/8.jpg"
                      content="王者荣耀比赛"
                      title="2017年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2018/1.jpg"
                      content="千岛湖旅游"
                      title="2018年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2019/1.jpg"
                      content="2019“好好学习，天天向上”六一儿童节"
                      title="2019年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2019/2.jpg"
                      content="第一届端午团队竞赛"
                      title="2019年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2019/3.jpg"
                      content="运营部表彰大会"
                      title="2019年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2020/1.jpg"
                      content="2020“无论多大，要相信童话和英雄”六一儿童节"
                      title="2020年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2020/3.jpg"
                      content="管理层溧阳天目湖团建"
                      title="2020年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2020/4.jpg"
                      content="前往上沙社区送温暖、慰问老人"
                      title="2020年"
                      @preview="handlePreview"
                    />
                  </div>
                  <div class="swiper-slide">
                    <home-fazhan
                      img="/img/2020/5.jpg"
                      content="掌玩集团经营者计划 第一期"
                      title="2020年"
                      @preview="handlePreview"
                    />
                  </div> -->
                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide home-swiper-slide slide-7">
          <div class="footer-swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div
                  class="home-box"
                  :style="{height:windowHeight+'px'}"
                >
                  <div class="home-box-header">
                    <p class="title">
                      <span class="cn">合作伙伴</span>
                      <span class="en">Cooperative partner</span>
                    </p>
                  </div>
                  <hezuo />
                </div>
              </div>
              <div class="swiper-slide">
                <footer-bar />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-show="footerSwiperIndex!==1"
        class="swiper-button-down"
        :class="{'up':footerSwiperIndex===1}"
        @click="swiperDown"
      >
        <img src="@/assets/icon/down.png">
      </div>
      <!-- 如果需要滚动条 -->
      <!-- <div class="swiper-scrollbar"></div> -->
    </div>
    <div
      v-show="swiperActiveIndex!==0"
      class="copyright"
    >
      Copyright©2019 杭州掌玩网络技术有限公司 <span><a
          href="http://www.beian.miit.gov.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >浙ICP备15008838号-16</a></span>
    </div>

    <preview-image
      v-model="previewVisible"
      :imgUrl="previewUrl"
    />
  </div>
</template>
<script>
import Swiper from 'swiper/dist/js/swiper.min.js'
import 'swiper/dist/css/swiper.css'
import Hezuo from '@/components/Hezuo'
import HeaderBarHome from '@/components/HeaderBarHome'
import HomeFazhan from '@/components/HomeFazhan'
import PreviewImage from '@/components/PreviewImage'

export default {
  title: 'Default',
  components: {
    Hezuo,
    HeaderBarHome,
    HomeFazhan,
    PreviewImage
  },
  data() {
    return {
      mySwiper: null,
      footerSwiper: null,
      swiperStatus: false,
      swiperActiveIndex: 0,
      clientHeight: 0,
      scale: 1,
      hezuoArr: [
        { name: 'tx', status: false },
        { name: 'zijie', status: false },
        { name: 'yuewen', status: false },
        { name: 'ks', status: false },
        { name: 'yz', status: false },
        { name: 'al', status: false }
      ],
      center: { lng: 120.331062, lat: 30.317323 },
      zoom: 13,
      windowHeight: 0,
      previewVisible: false,
      previewUrl: ''
    }
  },
  computed: {
    isWhite() {
      if (this.swiperActiveIndex === 0 || this.swiperActiveIndex === 8) {
        return true
      }
      return false
    },
    footerSwiperIndex() {
      return this.footerSwiper ? this.footerSwiper.realIndex : -1
    },
    footerSwiperProgress() {
      return this.footerSwiper ? this.footerSwiper.progress : 0
    }
  },
  watch: {
    'mySwiper.activeIndex': {
      handler: function (val) {
        // if (val === 6) {
        //   setTimeout(() => {
        //     this.footerSwiper && this.footerSwiper.update()
        //     this.footerSwiper && this.footerSwiper.slideTo(0)
        //   }, 100)
        // }
        const video = document.getElementById('video')
        if (val === 1) {
          setTimeout(() => {
            video.play()
          }, 500)
          console.log(video)
        } else {
          video.pause()
        }
        this.swiperActiveIndex = val
      }
    },
    'footerSwiper.activeIndex': {
      handler: function (val) {
        console.log('footerSwiper:', val)
      }
    }
  },
  mounted() {
    this.swiperInit()
    this.banner1Size()
    window.addEventListener('resize', this.banner1Size)
  },
  methods: {
    swiperInit() {
      var that = this
      var mySwiper = new Swiper('.swiper-container', {
        direction: 'vertical',
        // initialSlide: 6,
        preventInteractionOnTransition: true,
        // height: window.innerHeight,
        autoHeight: true,
        preloadImages: false,
        forceToAxis: true,
        // noSwiping: true,
        simulateTouch: false,
        mousewheelControl: true,
        slidesPerView: 1,
        releaseOnEdges: true,
        speed: 800,
        onInit: function () {
          window.addEventListener('wheel', that.controlMousewheel, !1)
          window.addEventListener('touchend', that.controlMousewheel, !1)
          console.log('init')
        },
        onSlideChangeEnd: function (swiper) {
          swiper.update()
        }
      })
      this.mySwiper = mySwiper
      this.mySwiper.on('slideChangeTransitionStart', () => {
        this.swiperStatus = true
      })
      this.mySwiper.on('slideChangeTransitionEnd', () => {
        this.swiperStatus = false
      })

      const yewuSwiper = new Swiper('.yewu-swiper-container', {
        nextButton: '.yewu-swiper-container .swiper-button-next',
        prevButton: '.yewu-swiper-container .swiper-button-prev'
      })

      const rongyuSwiper = new Swiper('.rongyu-swiper-container', {
        nextButton: '.rongyu-swiper-container .swiper-button-next',
        prevButton: '.rongyu-swiper-container .swiper-button-prev'
      })
      const rencaiSwiper = new Swiper('.rencai-swiper-container', {
        nextButton: '.rencai-swiper-container .swiper-button-next',
        prevButton: '.rencai-swiper-container .swiper-button-prev'
      })
      const zhaopinSwiper = new Swiper('.zhaopin-swiper-container', {
        centeredSlides: true,
        slidesPerView: 3,
        initialSlide: 1,
        roundLengths: true,
        resizeReInit: true,
        centeredSlides: true,
        nextButton: '.zhaopin-swiper-container .swiper-button-next',
        prevButton: '.zhaopin-swiper-container .swiper-button-prev'
      })
      const fazhanSwiper = new Swiper('.fazhan-swiper-container', {
        centeredSlides: true,
        slidesPerView: 3,
        initialSlide: 1,
        spaceBetween: 30,
        roundLengths: true,
        resizeReInit: true,
        centeredSlides: true,
        nextButton: '.fazhan-swiper-container .swiper-button-next',
        prevButton: '.fazhan-swiper-container .swiper-button-prev'
      })
      const footerSwiper = new Swiper('.footer-swiper-container', {
        direction: 'vertical',
        slidesPerView: 'auto',
        mousewheelControl: true,
        freeMode: true,
        freeModeMomentumVelocityRatio: 1,
        freeModeMomentumBounce: false,
        preventInteractionOnTransition: true,
        nested: true
      })
      this.footerSwiper = footerSwiper
    },
    swiperDown() {
      // if (this.swiperStatus) {
      //   return
      // }
      let index = this.mySwiper.activeIndex || 0
      if (index === 7) {
        this.footerSwiper.slideNext()
      } else {
        this.mySwiper.slideNext()
      }

      if (this.footerSwiperIndex === 1) {
        this.swiperTo(0)
      }
    },
    swiperTo(index) {
      this.mySwiper.slideTo(index, 800, false)
    },
    banner1Size(event) {
      const INIT_WIDTH = 1920
      const scale = window.innerWidth / INIT_WIDTH
      this.scale = scale * 1.2 > 1 ? 1 : scale * 1.2
      this.windowHeight = window.innerHeight ? window.innerHeight : document.documentElement && document.documentElement.clientHeight ? document.documentElement.clientHeight : void 0
    },
    scaleFun() {
      var scale = this.scale
      return `transform:scale(${scale})`
    },
    controlMousewheel() {
      // console.log(this.mySwiper.realIndex)

      if (this.mySwiper.realIndex === 8) {
        this.mySwiper.disableMousewheelControl()
        // console.log(this.mySwiper.realIndex, 'disable')
      }
      const footerWrap = document.querySelector('.footer-swiper-container .swiper-wrapper')
      const transform = footerWrap.style.transform
      // const y = transform ? +transform.split('px, ')[1] : 1
      // y === 0 ? this.mySwiper.mousewheel.enable() : ''
      // console.log(this.footerSwiperProgress)
      this.footerSwiperProgress === 0 ? (this.mySwiper.enableMousewheelControl(), console.log('enable')) : ''
    },
    handlePreview(url) {
      console.log('handlePreview', url)
      this.previewUrl = url
      this.previewVisible = true
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.banner1Size)
    window.removeEventListener('wheel', this.controlMousewheel)
    window.removeEventListener('touchend', this.controlMousewheel)
  }
}
</script>
<style lang="scss" scoped>
@keyframes toggleMove {
  0% {
    bottom: 5.5%;
    opacity: 0.3;
  }

  50% {
    bottom: 4.5%;
    opacity: 1;
  }

  to {
    bottom: 5.5%;
    opacity: 0.3;
  }
}
.swiper {
  // position: relative;
  // height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .home-swiper-slide {
    // display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    overflow: hidden;
  }
  .swiper-button-down {
    position: fixed;
    width: 26px;
    height: 20px;
    background-size: cover;
    z-index: 12;
    left: 50%;
    margin-left: -13px;
    bottom: 4%;
    transition: all 0.2s;
    animation: toggleMove 0.8s infinite;
    -moz-animation: toggleMove 10.8s infinite;
    -webkit-animation: toggleMove 0.8s infinite;
    -o-animation: toggleMove 0.8s infinite;
    cursor: pointer;
    &.up {
      transform: rotate(180deg);
    }
    img {
      height: 100%;
    }
  }
}
.swiper-container-vertical > .swiper-pagination-bullets {
  position: fixed;
}

div.copyright {
  position: fixed;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10;
  left: 99px;
  bottom: 20px;
  font-size: 12px;
  a {
    text-decoration: none;
    color: inherit;
  }
}
.slide {
  &-1 {
    background: url(https://wanxiaomeng-1255977238.cos.ap-shanghai.myqcloud.com/mxy/gw/new.jpg);
    background-position: center center;
    background-size: contain;
  }
}
.footer-swiper-container {
  .home-box {
    // padding-top: 80px;
    box-sizing: border-box;
  }
  .hezuo {
    top: 50%;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0;
  }
}

.yewu-img1 {
  background: url(https://wanxiaomeng-1255977238.cos.ap-shanghai.myqcloud.com/mxy/gw/slide22.png);
}
.yewu-img2 {
  background: url(https://wanxiaomeng-1255977238.cos.ap-shanghai.myqcloud.com/mxy/gw/slide11.png);
}
.yewu-img3 {
  background: url(https://wanxiaomeng-1255977238.cos.ap-shanghai.myqcloud.com/mxy/gw/slide2.jpg);
}
.yewu-img4 {
  background: url(/bg/new/slide4.png);
}

.rongyu-img1 {
  background: url(https://wanxiaomeng-1255977238.cos.ap-shanghai.myqcloud.com/mxy/gw/ry5.png);
}
.rongyu-img2 {
  background: url(https://wanxiaomeng-1255977238.cos.ap-shanghai.myqcloud.com/mxy/gw/ry3.png);
}
.rongyu-img3 {
  background: url(https://wanxiaomeng-1255977238.cos.ap-shanghai.myqcloud.com/mxy/gw/ry7.jpeg);
}
.rongyu-img4 {
  background: url(/rongyuimg/ry1.png);
}
.rongyu-img5 {
  background: url(/rongyuimg/ry8.jpeg);
}
.rongyu-img6 {
  background: url(/rongyuimg/ry9.jpeg);
}
.rongyu-img7 {
  background: url(/rongyuimg/new/ry1.jpeg);
}
.rongyu-img8 {
  background: url(/rongyuimg/new/ry9.jpeg);
}
.rongyu-img9 {
  background: url(/rongyuimg/ry11.jpeg);
}
.rongyu-img10 {
  background: url(/rongyuimg/ry12.jpeg);
}
.zhaopin-img1 {
  background-image: url(/zhaopin/1.jpg);
}
.zhaopin-img2 {
  background-image: url(/zhaopin/2.jpg);
}
.zhaopin-img3 {
  background-image: url(/zhaopin/3.jpg);
}
.zhaopin-img4 {
  background-image: url(/zhaopin/4.jpg);
}
.zhaopin-img5 {
  background-image: url(/zhaopin/5.jpg);
}
.zhaopin-img6 {
  background-image: url(/zhaopin/6.jpg);
}
.rencai-img1 {
  background-image: url(/rc/new/rc2.png);
}
.rencai-img2 {
  background-image: url(/rc/new/rc3.png);
}
.rencai-img3 {
  background-image: url(/rc/new/rc1.png);
}
.rencai-img4 {
  background-image: url(/img/2020/5.jpg);
}
.rencai-img5 {
  background-image: url(/rc/rc5.jpeg);
}
.rencai-img6 {
  background-image: url(/rc/rc7.jpg);
}
// .fazhan-img1 {
//   // background-image: url(/fazhanimg/fz1.png);
// }
// .fazhan-img2 {
//   // background-image: url(/fazhanimg/fz2.jpg);
// }
// .fazhan-img3 {
//   // background-image: url(/fazhanimg/fz3.jpg);
// }
// .fazhan-img4 {
//   // background-image: url(/fazhanimg/fz4.jpg);
// }
// .fazhan-img5 {
//   // background-image: url(/fazhanimg/fz5.jpg);
// }
// .fazhan-img6 {
//   // background-image: url(/fazhanimg/fz6.jpg);
// }
// .fazhan-img7 {
//   // background-image: url(/fazhanimg/fz7.jpg);
// }
// .fazhan-img8 {
//   // background-image: url(/fazhanimg/fz8.jpg);
// }
// .fazhan-img9 {
//   // background-image: url(/fazhanimg/fz9.jpg);
// }
// .fazhan-img10 {
//   // background-image: url(/fazhanimg/fz10.jpg);
// }

.video {
  width: 70%;
  max-width: 1360px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .img {
    text-align: center;
    padding: 0 17%;
  }
  video {
    max-width: 100%;
    // margin: 0 auto;
  }
  .title {
    color: #ed2907;
    font-size: 22px;
    margin-top: 4%;
  }
  .content {
    line-height: 31px;
    font-size: 14px;
  }
}
</style>